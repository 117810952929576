import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

function Games() {
  return (
    <>
      <div>Games</div>
      <a href="https://knockout.vgmrx.com">knockout.vgmrx.com</a>
    </>
  );
}

export default Games;
