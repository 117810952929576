import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

function Home() {
  return (
    <Grid>
      <Row center="xs" style={{ padding: '40px' }}>
        <Col xs={12}>
          <div>i make games</div>
          <Row>
            <Col xs>
              <iframe height="360px" width="320px" src='https://knockout.vgmrx.com/'></iframe>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row center="xs" style={{ padding: '40px' }}>
        <Col xs={12}>
          <div>i make websites</div>
        </Col>
      </Row>
      {/* <Row center="xs" style={{ padding: '40px' }}>
        <Col xs={12}>
          <div>i make music</div>
        </Col>
      </Row> */}
    </Grid>
  );
}

export default Home;
